import React from 'react';
import { FiActivity, FiCast, FiMap } from "react-icons/fi";
import { TbWorldWww } from "react-icons/tb"
import { FaMap } from "react-icons/fa"
import { SiAdobephotoshop } from "react-icons/si"
import { MdAdsClick } from "react-icons/md"
import { FaSearch, FaCamera } from "react-icons/fa"
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        icon: <TbWorldWww />,
        title: 'Website Development',
        description: 'We create custom, responsive websites tailored to your business needs, ensuring optimal performance and user experience.'
    },
    {
        icon: <FaMap />,
        title: 'Design & Creative Art',
        description: 'Our services include innovative graphic design and multimedia content to visually represent and enhance your brand.'
    },
    {
        icon: <MdAdsClick />,
        title: 'Digital Marketing',
        description: 'We offer comprehensive digital marketing strategies to increase your online presence and drive business growth.'
    },
    {
        icon: <FaSearch />,
        title: 'SEO & Content Marketing',
        description: 'Our SEO and content marketing services improve your search engine rankings and engage your audience with high-quality content.'
    },
    {
        icon: <FaCamera />,
        title: 'Photography/Videography: ',
        description: 'Our SEO and content marketing services improve your search engine rankings and engage your audience with high-quality content.'
    },
    {
        icon: <SiAdobephotoshop />,
        title: 'Graphic designing',
        description: 'Our graphic designing services deliver creative and visually compelling designs that elevate your brand identity worldwide.'
    },
]

const ServiceOne = ({textAlign, serviceStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;