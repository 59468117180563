import React, { useEffect } from 'react';

const Darkmode = () => {
    useEffect(() => {
        // Get theme from local storage
        const theme = localStorage.getItem('theme')
        console.log("Theme", theme)
        if (theme) {
            if (theme === 'light') {
                document.querySelector('body').classList.add("light")
            } else {
                document.querySelector('body').classList.add("dark")
            }
        } else {
            // Set default theme to dark
            localStorage.setItem('theme', 'dark')
            document.querySelector('body').classList.add("dark")
        }
    }, [])

    const switchTheme = (e) => {
        // Get theme from local storage
        const theme = localStorage.getItem('theme')

        if (theme) {
            if (theme === 'light') {
                localStorage.setItem('theme', 'dark')
                document.querySelector('body').classList.remove("light")
                document.querySelector('body').classList.add("dark")
            } else {
                localStorage.setItem('theme', 'light')
                document.querySelector('body').classList.remove("dark")
                document.querySelector('body').classList.add("light")
            }
        }
    }
    return (
        <button id="darkmode" onClick={(e) => switchTheme(e)}>
           <img className="light-icon" src={`${process.env.PUBLIC_URL}/images/icons/sun-01.svg`} alt="Sun images" />
           <img className="dark-icon" src={`${process.env.PUBLIC_URL}/images/icons/vector.svg`} alt="Sun images" />
        </button>
    )
}
export default Darkmode;